<template>
  <form @submit.prevent="submit">
    <div class="row" v-if="formData.userId.value">
      <div class="col">
        <div class="form-group">
          <label>ID</label>
          <input
            type="text"
            class="form-control"
            disabled
            v-model="formData.userId.value"
          />
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': !formData.name.valid }"
            v-model.trim="formData.name.value"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Email</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': !formData.email.valid }"
            v-model.trim="formData.email.value"
          />
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <div class="form-group">
          <label>Nova senha</label>
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': !formData.newPassword.valid }"
            v-model.trim="formData.newPassword.value"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Repita a nova senha</label>
          <input
            type="password"
            class="form-control"
            :class="{ 'is-invalid': !formData.newPassword2.valid }"
            v-model.trim="formData.newPassword2.value"
          />
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label>Status</label>
        <select class="form-select" :class="{ 'is-invalid': !formData.status.valid }" v-model="formData.status.value">
          <option value="active">Ativo</option>
          <option value="inactive">Inativo</option>
          <option value="blocked">Bloqueado</option>
        </select>
      </div>

      <div class="col">
        <label>Administrador</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            v-model="formData.admin"
          />
          <label class="form-check-label">
            Este usuário é administrador.
          </label>
        </div>

        <div class="form-check" v-if="formData.admin">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            :class="{ 'is-invalid': !formData.checkAdmin.valid }"
            v-model="formData.checkAdmin.value"
          />
          <label class="form-check-label">
            Estou ciente de que este usuário passará a ter privilégios de
            administrador.
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <h4>Permissões</h4>
    </div>

    <span class="p-input-icon-right">
      <i class="pi pi-search" />
      <InputText type="text" v-model="filterPermission" placeholder="Busque por permissões" />
    </span>

    <div class="row mt-3" >
      <div class="field-checkbox col-3 my-2" v-for="perm in filteredPermissions" :key="perm.name">
        <Checkbox :inputId="perm.name" :value="perm.name" v-model="formData.permissions" class="me-2" />
        <label :for="perm.name"> {{ perm.description }} </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <button class="btn btn-primary me-3">Salvar</button>
        <router-link class="btn btn-light" to="/config/users">Cancelar</router-link>
      </div>
      <div class="col">
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Checkbox from 'primevue/checkbox';
import listPermissions from '@/constants/listPermissions';
import InputText from 'primevue/inputtext';

export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
  },
  components: { Checkbox, InputText },
  data() {
    return {
      formData: {
        userId: {
          value: this.user?.userId
        },
        name: {
          value: this.user.name,
          valid: true,
        },
        email: {
          value: this.user.email,
          valid: true,
        },
        newPassword: {
          value: '',
          valid: true,
        },
        newPassword2: {
          value: '',
          valid: true,
        },
        status: {
          value: this.user.status,
          valid: true,
        },
        admin: this.user.admin,
        checkAdmin: {
          value: false,
          valid: true,
        },
        permissions: this.user.permissions,
      },
      validForm: true,
      filterPermission: '',
    };
  },
  computed: {
    ...mapGetters(['apiEndpoint']),
    filteredPermissions() {
      return listPermissions.filter((el) => el.description.toLowerCase().includes(this.filterPermission.toLowerCase()));
    },
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    validateForm() {
      this.formData.name.valid = true;
      this.formData.email.valid = true;
      this.formData.newPassword.valid = true;
      this.formData.newPassword2.valid = true;
      this.formData.status.valid = true;
      this.formData.checkAdmin.valid = true;
      this.validForm = true;

      // Test name
      if (this.formData.name.value === "") {
        this.formData.name.valid = false;
        this.validForm = false;
        this.$toast.add({
          severity: "error",
          summary: "O nome não pode ser vazio.",
        });
      }

      // Test email
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.formData.email.value
        )
      ) {
        this.formData.email.valid = false;
        this.validForm = false;
        this.$toast.add({ severity: "error", summary: "E-mail inválido." });
      }

      // Test status
      if (this.formData.status.value === "") {
        this.formData.status.valid = false;
        this.validForm = false;
        this.$toast.add({
          severity: "error",
          summary: "Você precisa definir o status deste usuário.",
        });
      }

      // If admin is flagged then admin check must be ok too
      if (this.formData.admin && !this.formData.checkAdmin.value) {
        this.formData.checkAdmin.valid = false;
        this.validForm = false;
        this.$toast.add({
          severity: "error",
          summary:
            "Você deve confirmar a permissão especial de administrador deste usuário.",
        });
      }

      // If this is a new user, password is mandatory
      if (this.id == '') {
        // Test passwords
        if (!this.formData.newPassword.value) {
          this.formData.newPassword.valid = false;
          this.validForm = false;
          this.$toast.add({severity:'error', summary: 'A nova senha não pode ser vazia.'});
        }

        if (this.formData.newPassword.value != this.formData.newPassword2.value) {
          this.formData.newPassword.valid = false;
          this.formData.newPassword2.valid = false;
          this.validForm = false;
          this.$toast.add({severity:'error', summary: 'As senhas não conferem.'});
        }
      }

      return this.validForm;
    },
    submit() {
      if (this.validateForm()) {
        this.$emit('save', this.formData);
      }
    }
  },
  emit: ['submit'],
};
</script>

<style scoped>
table tr {
  text-align: center;
}
table tr td:first-child,
table tr th:first-child {
  text-align: left;
}
</style>