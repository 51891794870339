import axios from 'axios';
import store from '@/store';

export default {
  async getUser(userId) {
    const url = `${store.getters.apiEndpoint}/users/${userId}`;
    const { data } = await axios.get(url);

    return data;
  },

  /**
   * Updates user to API
   * @param { object } user User data
   * @param { number } userId User ID
   */
  async updateUser(user, userId) {
    const url = `${store.getters.apiEndpoint}/users/${userId}`;
    await axios.post(url, user);
  },
  
  async saveUser(user) {
    const url = `${store.getters.apiEndpoint}/users`;
    await axios.post(url, user);
  },

  async saveUserSetting(setting) {
    const url = `${store.getters.apiEndpoint}/me/settings`;
    await axios.post(url, setting);
  }
}