<template>
  <h1>Edição de usuário</h1>
  <form-user :user="user" v-if="loaded" @save="saveUser" />
</template>

<script>
import FormUser from './Form.vue'
import userApi from '@/api/users';

export default ({
  components: { FormUser },
  props: [ "userId" ],
  data: () => ({
    user: {},
    loaded: false,
  }),
  methods: {
    async fetchUser() {

      try {
        this.user = await userApi.getUser(this.userId);
        this.loaded = true;
      } catch {
        this.$toast.add({
          severity: "error",
          summary: "Não foi possível resgatar os dados do usuário.",
        });

        this.$router.push('/config/users');
      }
    },
    async saveUser(user) {
      const payload = {
        name       : user.name.value,
        email      : user.email.value,
        newPassword: user.newPassword.value,
        status     : user.status.value,
        admin      : user.admin,
        permissions: user.permissions
      }

      try {
        await userApi.updateUser(payload, user.userId.value);
        this.$toast.add({severity:'success', summary: 'Dados salvos.', life: 3000});

        this.$router.push('/config/users');
      } catch (error) {
        switch (error.response.data.errorCode) {
          case 1:
            this.$toast.add({severity:'error', summary: 'Já existe um usuário com este e-mail.'});
            break;

          default:
            this.$toast.add({severity:'error', summary: 'Não foi possível salvar os dados.'});
        }
      }
    }
  },
  created() {
    this.fetchUser();
  }
})
</script>
