export default [
  {
    name: 'file_create',
    description: 'Criar arquivo',
  },
  {
    name: 'file_read',
    description: 'Ler arquivo',
  },
  {
    name: 'file_update',
    description: 'Atualizar arquivo',
  },
  {
    name: 'file_delete',
    description: 'Remover arquivo',
  },

  {
    name: 'folder_create',
    description: 'Criar pasta',
  },
  {
    name: 'folder_read',
    description: 'Ler pasta',
  },
  {
    name: 'folder_update',
    description: 'Atualizar pasta',
  },
  {
    name: 'folder_delete',
    description: 'Remover pasta',
  },
  
  {
    name: 'tags_create',
    description: 'Criar tag',
  },
  {
    name: 'tags_read',
    description: 'Ler tags',
  },
  {
    name: 'tags_update',
    description: 'Atualizar tag',
  },
  {
    name: 'tags_delete',
    description: 'Remover tag',
  },

  {
    name: 'user_management',
    description: 'Gestão de usuários',
  }
]
